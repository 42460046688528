import React, { Suspense, useState, useEffect } from "react";
import "./style.css";
import { Loader } from "../../common/styled-components/loader";
import { useSelector, useDispatch } from "react-redux";
import {getMainRole, isAuth} from "../../../utils/utils";
import { useHistory, Link } from "react-router-dom";
import {Container, Row, Col, Card, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {
    studentActivitiesByTeacherAPI
} from "../../../api/data-analysis-api";
import {FaUserLock, FaSearch, FaArrowCircleLeft, FaFilter} from "react-icons/all";
import Pagination from "@material-ui/lab/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Portal} from "react-overlays";
import {ROLE_ADMIN_NAME, ROLE_EMPLOYEE_NAME, ROLE_PRINCIPAL_NAME, ROLE_TEACHER_NAME} from "../../../config/environment";

const ReportStudentsActivityTeacher = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(["report-connections"]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalResults, setTotalResults] = React.useState(null);
    const [results, setResults] = useState([]);
    const [pagination, setPagination] = useState({});
    const [documentUrl, setDocumentUrl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState(new Date());
    const count = Math.ceil(totalResults / pageSize);

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");
        return <Portal container={el}>{children}</Portal>;
    };

    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value);
        const res = await studentActivitiesByTeacherAPI(startDate, endDate, false, pageSize, value - 1);
        setTotalResults(res.data.count);
        setResults(res.data.results);
        setLoading(false);
    };

    useEffect(() => {
        setPagination({ page, pageSize });
    }, [page]);


    useEffect(() => {
        if (isAuth()) {
            history.push("/analysis/student-activities-teacher");
        }
        if(getMainRole().localeCompare(ROLE_TEACHER_NAME) !== 0)
        {
            history.push("/analysis/dashboard");
        }
    }, []);


    const onFormSearch = async ()=>{
        setLoading(true);
        setDocumentUrl(null);
        const res = await studentActivitiesByTeacherAPI(startDate, endDate, false, pageSize, 0);
        setPage(0)
        setTotalResults(res.data.count);
        setResults(res.data.results);
        setLoading(false);
    }

    const onDocumentSearch = async ()=>{
        setLoading(true);
        const res = await studentActivitiesByTeacherAPI(startDate, endDate, true, pageSize, 0);
        if(typeof res.data !== undefined && res.data.file !== ""){
            const link = document.createElement('a');
            link.href = res.data.file;
            link.setAttribute('download', res.data.file);
            document.body.appendChild(link);
            link.click();
        }
        setLoading(false);
    }

    return (
        <>
            <Container  className="deliverables-wrapper-container">
                <Row>
                    <Col>
                        <Link className="btn-back" to="analysis/dashboard"><FaArrowCircleLeft/> {t("back")}</Link>
                        <br/>
                        <h1 className="title margin-top-30">
                            {t("report-title")}
                        </h1>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="box-shadow animated animate__animated animate__fadeInLeft reports-clear-card mt-negative module-filter">
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <h6 className="title-filter"> <FaFilter/> {' '} {t("filters")}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="wraper-input wraper-input__flexEnd filter-activity-teacher mt-3">
                                            <div className="wrapper-input__col"><sup>{t("from")}</sup> <DatePicker selected={startDate} popperContainer={CalendarContainer} onChange={date => setStartDate(date)} /></div>
                                            <div className="wrapper-input__col"><sup>{t("to")}</sup>{' '}<DatePicker selected={endDate} popperContainer={CalendarContainer} onChange={date => setEndDate(date)} /></div>
                                            <div className="wrapper-input__col">
                                                <button className="button-secondary" onClick={onFormSearch}>
                                                <FaSearch/> {' '}
                                                 {t("search")}
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col style={{textAlign: 'right'}}>
                                        <button className="button-secondary" onClick={onFormSearch}>
                                            <FaSearch/> {' '}
                                            {t("search")}
                                        </button>
                                    </Col>
                                </Row> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <hr/>
                </Row>
                <Row>
                    
                    <Col className="animated animate__animated animate__fadeInLeft text-right">
                        <div className="wrapper-reports-btn-results">
                        {
                            results.length > 0 ?
                                <>
                                    <button onClick={onDocumentSearch} className="button-download-document animated animate__animated animate__fadeIn">
                                        {t("download-excel-activity")}
                                    </button>
                                    {' '}
                                    <b className="animated animate__animated animate__fadeIn">{t("total")}: {totalResults} </b>
                                </> : null
                        }
                        </div>
                    </Col>                    
                </Row>
                <Row>
                    <Col className="animated animate__animated animate__fadeIn">
                        <Card className="box-shadow animated animate__animated animate__fadeInLeft reports-clear-card">
                            {/* <Card.Body className="card-white-gray"> */}
                            <Card.Body>
                                {
                                    results.length > 0 ?
                                        <table className="animated animate__animated animate__fadeIn content-table-users">
                                            <thead>
                                            <tr>
                                                <th>{t("sie")}</th>
                                                <th>{t("user")}</th>
                                                <th>{t("connected-date")} (UTC)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {results.map((item, i)=>(
                                                <tr key={i}>
                                                    <td>
                                                <span className="table-users--title">
                                                {item.user.sie}
                                                </span>
                                                    </td>
                                                    <td>
                                                 <span className="table-users--general">
                                                    {item.user.first_name}{' '} {item.user.last_name}
                                                     
                                                    <small>{item.user.email}</small>
                                                     <br/>
                                                     <small><b>{item.user.role}</b></small>
                                                 </span>
                                                    </td>
                                                    <td>
                                                <span className="table-users--general table-users--date">
                                                {item.connected_date}
                                                </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table> :
                                        <div className="text-center center-item margin-top-30">
                                            <FaSearch className="gray-text" size={60}/>
                                            <br/>
                                            <p className="gray-text"> {t("results")}</p>
                                        </div>
                                }
                            </Card.Body>
                        </Card>
                        <br/>
                        {count > 0 && (
                            <Pagination
                                count={count}
                                page={page}
                                onChange={handlePageChange}
                            />
                        )}
                    </Col>
                </Row>
                {loading ? <Loader /> : null}
            </Container>
        </>
    );
};

export default ReportStudentsActivityTeacher;
